export default {
  props: ['show', 'list', 'init'],
  data() {
    return {
      imgList: []
    };
  },
  computed: {
    showBack: {
      get() {
        return this.show;
      },
      set() {
        this.$emit('close');
      }
    }
  },
  methods: {
    changeBackVisible(item) {
      item.showBackImg = !item.showBackImg;
      // this.list.splice(index,1,item);
    }
  }
};