import { env } from "md-base-tools/env";
import { mapState } from "vuex";
// eslint-disable-next-line no-unused-vars
import { convertibleList, exchangeRecord, exchangeMode } from "@/api";
// eslint-disable-next-line no-unused-vars
import { List } from "vant";
import BigImage from "@@/luckycards/bigImage.vue";
export default {
  components: {
    BigImage
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  data() {
    return {
      env,
      showError: false,
      showMessage: "",
      loading: true,
      showDia: false,
      miniProgram: "",
      card_name: "",
      level_name: "全部",
      nav: [{
        level_name: "全部"
      }],
      second_relation: this.$route.query.second_relation,
      activity_name: this.$route.query.activity_name,
      activity_id: this.$route.query.activity_id,
      card_detail_list: [],
      order_id_list: [],
      modeList: [],
      historyList: [],
      bigImgItem: {},
      modeItem: null,
      page: 1,
      timer: null,
      card_id: "",
      total: 0,
      page_size: 20,
      is_next: false,
      showimg: false,
      hasCheckAll: false,
      bigImageVisible: false,
      arrival_time: "",
      allTypeNum: 500,
      bigInitIndex: 0,
      img_list: [],
      get_card_id: 0
    };
  },
  beforeMount() {},
  async mounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnav.style.top = 0;
    window.userHeader.titleText("卡牌兑换");
    await this.convertibleList();
    await this.exchangeRecord();
    await this.exchangeMode();
  },
  unmounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnav.style.top = "1.6rem";
  },
  methods: {
    jump() {
      if (this.modeItem && this.modeItem.select) {
        this.$router.push(`/hd/container_list?second_relation=${this.modeItem.second_relation}&activity_name=${this.modeItem.level_name}&allTypeNum=${this.modeItem.consumer_num}&card_id=${this.card_id}`);
      }
    },
    closeDia() {
      this.showDia = false;
    },
    // 兑换记录
    async exchangeRecord() {
      const {
        data
      } = await exchangeRecord();
      this.historyList = data;
    },
    // 兑换方式
    async exchangeMode() {
      const {
        data
      } = await exchangeMode();
      this.modeList = data.map(e => {
        e.select = false;
        return e;
      });
    },
    itemSelect(item) {
      this.modeList.forEach(e => {
        e.select = false;
      });
      if (item.have_num >= item.consumer_num) {
        item.select = true;
      }
      this.modeItem = item;
    },
    // 点击卡片
    checkCard(item) {
      if (this.card_id === item.card_id) {
        this.card_id = "";
      } else {
        if (item.stock_num) {
          this.card_id = item.card_id;
        }
      }
    },
    // 获取兑换列表
    async convertibleList() {
      const {
        data
      } = await convertibleList();
      this.card_detail_list = data;
    },
    submit() {
      this.showDia = true;
      this.showimg = false;
      this.bigImgItem = "";
    },
    showBigImage(item) {
      this.bigImgItem = item;
      this.showDia = true;
      this.showimg = true;
    }
  }
};