import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-23d1f332"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "bigImage"
};
const _hoisted_2 = {
  key: 0,
  class: "tab"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "card"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "level"
};
const _hoisted_9 = {
  class: "level_name"
};
const _hoisted_10 = {
  class: "render-font-family"
};
const _hoisted_11 = {
  class: "custom-indicator"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_swipe_item = _resolveComponent("van-swipe-item");
  const _component_van_swipe = _resolveComponent("van-swipe");
  return $options.showBack ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_swipe, {
    "initial-swipe": $props.init,
    loop: false
  }, {
    indicator: _withCtx(({
      active,
      total
    }) => [_createElementVNode("div", _hoisted_11, _toDisplayString(active + 1) + "/" + _toDisplayString(total), 1)]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (item, index) => {
      return _openBlock(), _createBlock(_component_van_swipe_item, {
        key: index,
        onClick: _cache[1] || (_cache[1] = $event => $options.showBack = false)
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "card_field",
          onClick: _cache[0] || (_cache[0] = _withModifiers($event => $options.showBack = true, ["stop"]))
        }, [item.card_back_img_url ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", {
          class: _normalizeClass([{
            'active': !item.showBackImg
          }]),
          onClick: _withModifiers($event => $options.changeBackVisible(item, index), ["stop"])
        }, "图1", 10, _hoisted_3), _createElementVNode("div", {
          class: _normalizeClass([{
            'active': item.showBackImg
          }]),
          onClick: _withModifiers($event => $options.changeBackVisible(item, index), ["stop"])
        }, "图2", 10, _hoisted_4)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, [item.showBackImg ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: `${_ctx.$imgUrlFilter(item.card_back_img_url, 470)}`
        }, null, 8, _hoisted_6)) : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: `${_ctx.$imgUrlFilter(item.card_img_url, 470)}`
        }, null, 8, _hoisted_7)), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString(item.level_name), 1)]), _createElementVNode("p", _hoisted_10, _toDisplayString(item.card_name), 1)])])]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }, 8, ["initial-swipe"])])) : _createCommentVNode("", true);
}